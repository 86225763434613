import React, {Component} from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Jumbotron
} from "reactstrap";

import EmailIcon from "@material-ui/icons/Email";
// import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import PersonIcon from "@material-ui/icons/Person";

import DOMPurify from "dompurify";

import "./styles.css";

class CV extends Component {
    render() {
        return (
            <div>
                <p>Hello</p>
            </div>
        );
    }
}

class Pub extends Component {
    getPaperByType(type) {
        if (this.props.isLoaded) {
            // console.log(this.props.data.filter(art => art.type === type))
            return this.props.data.filter(art => art.type === type);
        }
        return [];
    }

    renderArticle(art, index) {
        let author = <React.Fragment>{art.author.split("K. Hasselmann")[0]}<b>K. Hasselmann</b>{art.author.split("K. Hasselmann")[1]} | {" "}</React.Fragment>
        return (
            <p key={index}>
                {author}
                <strong>
                    <a href={art.link}>{art.title}</a>
                </strong>{" "}
                | <i>{art.journal}</i>
            </p>
        );
    }

    render() {
        let journal = this.getPaperByType("journal").map(this.renderArticle);
        let conf = this.getPaperByType("conf").map(this.renderArticle);
        let tecrep = this.getPaperByType("tecrep").map(this.renderArticle);
        let book = this.getPaperByType("book").map(this.renderArticle);

        return (
            <div className="container pt-4 pb-5">
                <div className="row">
                    <div className="col-12 fadein">
                        <h3 className="myborder">List of publications</h3>
                        <h5 className="myborder">Peer-reviewed journal articles</h5>
                        {journal}
                        <h5 className="myborder">International conference and workshop articles</h5>
                        {conf}
                        <h5 className="myborder">Books</h5>
                        {book}
                        <h5 className="myborder">Technical reports</h5>
                        {tecrep}
                    </div>
                </div>
            </div>
        );
    }
}

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log(this.props.data)
        return (
            <div className="container pt-4 pb-5 fadein">
                <div className="row">
                    <div className="col-12 col-lg-9">
                        <h3>Introducing myself</h3>
                        <img
                            src={`${process.env.PUBLIC_URL}/${this.props.isLoaded ? this.props.data.pic : ""}`}
                            width="175px"
                            className="rounded border-custom float-left mr-3 mb-2"
                            alt="Ken Hasselmann"
                        />
                        <div dangerouslySetInnerHTML={{__html: this.props.isLoaded ? DOMPurify.sanitize(this.props.data.intro) : ""}} />
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="card bg-light mb-3">
                            <div className="card-header">
                                <PersonIcon /> About
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">
                                    {this.props.isLoaded ? this.props.data.name : ""}
                                </h5>
                                <p className="card-text">
                                    <EmailIcon />
                                    {this.props.isLoaded ? this.props.data.mail : ""}
                                </p>
                                {// <p className="card-text">
                                    //     <PhoneIcon />
                                    //     {this.props.isLoaded ? this.props.data.phone : ""}
                                    // </p>
                                }
                                <p className="card-text" style={{whiteSpace: "pre-wrap"}}>
                                    <RoomIcon />
                                    {this.props.isLoaded ? this.props.data.address : ""}
                                </p>
                                <p>
                                    <a href={this.props.isLoaded ? this.props.data.orcid : ""} style={{textDecoration: "none"}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/orcid.png`} alt="ORCID" data-toggle="tooltip" data-placement="bottom" width="27" />
                                    </a>
                                    <a href={this.props.isLoaded ? this.props.data.scholar : ""} style={{textDecoration: "none"}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/scholar.png`} alt="Scholar" data-toggle="tooltip" data-placement="bottom" width="27" />
                                    </a>
                                    <a href={this.props.isLoaded ? this.props.data.linkedin : ""} style={{textDecoration: "none"}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/linkedin.png`} alt="LI" data-toggle="tooltip" data-placement="bottom" width="27" />
                                    </a>
                                    <a href={this.props.isLoaded ? this.props.data.researchg : ""} style={{textDecoration: "none"}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/researchgate.png`} alt="RG" data-toggle="tooltip" data-placement="bottom" width="27" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isLoaded: false,
            page: 0,
            data: {},
            scroll: "50%"
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data.json`)
            .then(response => response.json())
            .then(data => this.setState({data}))
            .then(() => this.setState({isLoaded: true}));
        window.addEventListener("scroll", e => this.handleScroll(e));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", e => this.handleScroll(e));
    }

    handleScroll(event) {
        let scroll = document.documentElement.scrollTop;
        this.setState({scroll: "50% " + (60 - scroll / 10) + "%"});
    }

    render() {
        let activePage = <Intro />;
        if (this.state.page === 0) {
            activePage = (
                <Intro data={this.state.data.me} isLoaded={this.state.isLoaded} />
            );
        } else if (this.state.page === 1) {
            activePage = (
                <Pub data={this.state.data.articles} isLoaded={this.state.isLoaded} />
            );
        } else if (this.state.page === 2) {
            activePage = <CV />;
        }

        return (
            <div className="App">
                <div>
                    <Navbar color="dark" dark expand="md">
                        <NavbarBrand href="/">~KH</NavbarBrand>
                        <NavbarToggler
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}
                        >
                            {"-->"}
                        </NavbarToggler>
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink href="#" onClick={() => this.setState({page: 0})}>
                                        Home
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => this.setState({page: 1})}>
                                        Publications
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://kenn7.github.io">
                                        Teaching
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>
                <NavLink href="#" onClick={() => this.setState({ page: 2 })}>
                    CV
                  </NavLink>
                </NavItem> */}
                            </Nav>
                        </Collapse>
                    </Navbar>
                    <Jumbotron
                        className="paral"
                        style={{backgroundPosition: this.state.scroll, backgroundImage: `url(${process.env.PUBLIC_URL}/${this.state.isLoaded ? this.state.data.me.background : ""}`}}
                    >
                        <h1 className="display-3">{`${this.state.isLoaded ? this.state.data.me.name : ""}`}</h1>
                        <p className="lead">PhD in Swarm Robotics.</p>
                    </Jumbotron>
                </div>
                {activePage}
            </div>
        );
    }
}

export default App;
